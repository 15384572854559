import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import battery from "./battery.png";
import quikvue from "./quikvue.png";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "#FFA100",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FAQsSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 10,
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid
            container
            sx={{
              padding: 0,
              marginTop: 1,
            }}
            maxWidth={"100%"}
            justifyContent="space-evenly"
          >
            <Grid item xs={12} md={4}>
              <Typography
                variant={"h5"}
                color={"primary"}
                sx={{ padding: 0, fontWeight: "bold" }}
              >
                Frequently Asked Questions
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Which smartphones are compatible?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    We can not ensure that it works in the regular camera of the
                    smartpbone or any other application. We advise the use just
                    for the{" "}
                    <a
                      href="/microrec-app"
                      style={{ color: "#FFA100" }}
                      target="_blank"
                    >
                      MicroREC App
                    </a>{" "}
                    (our free application to improve your capturing).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    What is the required battery?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    There is no battery, the QuikVue is rechargeble.
                  </Typography>
                  <img src={battery} style={{ maxWidth: "100%" }}></img>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    What are the use cases?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <img src={quikvue} style={{ maxWidth: "100%" }}></img>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
